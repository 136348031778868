import Meta from '@components/common/Head';
import Layout from '@components/common/Layout';
import { Container, Stack } from '@mui/material';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Section1 from '@components/Home/Section1';
import Section10 from '@components/Home/Section10';
import Section11 from '@components/Home/Section11';
import Section12 from '@components/Home/Section12';
import Section14 from '@components/Home/Section14';
import Section15 from '@components/Home/Section15';
import Section16 from '@components/Home/Section16';
import Section2 from '@components/Home/Section2';
import Section3 from '@components/Home/Section3';
import Section4 from '@components/Home/Section4';
import Section5 from '@components/Home/Section5';
import Section6 from '@components/Home/Section6';
import Section7 from '@components/Home/Section7';
import Section8 from '@components/Home/Section8';
import Section9 from '@components/Home/Section9';

export default function Home() {
  return (
    <Layout
      layoutProps={{
        sectionProps: {
          sx: {
            mt: 8.5,
          },
        },
      }}
    >
      <Meta
        title={'Unlocking your building’s sustainability, well-being & cost efficiency with nature.'}
        description={
          'naturethings is a tech platform that helps you harness the power of digitizing and transforming your urban environment with an AI-IoT platform, enhancing the potential of your building, site, or modular real estate space.'
        }
        imageUrl="https://cf-simple-s3-origin-cloudfrontfors3-360504420918.s3.us-east-1.amazonaws.com/nt_cover1.jpeg"
      />
      <Container maxWidth={false} sx={{ padding: '0 !important' }}>
        <Stack gap={{ xs: 6, md: 10 }}>
          <Stack gap={{ xs: 3, md: 6 }}>
            <Section1 />
            <Section2 />
            <Section14 />
          </Stack>

          <Stack gap={{ xs: 3, md: 6 }}>
            <Section3 />
            <Section4 />
          </Stack>

          <Section12 />
          <Section9 />

          <Stack gap={3}>
            <Stack gap={1}>
              <Section6 />
              <Section7 />
            </Stack>
            <Section8 />
          </Stack>

          <Stack gap={3}>
            <Section10 />
            <Section11 />
          </Stack>
          <Section5 />
        </Stack>
        <Stack
          gap={6}
          sx={{
            background: '#eaeaea',
            pt: '60px',
          }}
        >
          <Section15 />

          <Section16 />
        </Stack>
      </Container>
    </Layout>
  );
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'discover'])),
    },
  };
}
